import unhead_HfNdB4VzeL from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._gldaeu2wafoqs5nf4t4kkwmlre/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_W4flS4JDgo from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._gldaeu2wafoqs5nf4t4kkwmlre/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RILlUTRFhw from "/var/www/html/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.9.0_sass@1.81_chwz3n7sx62u4goi5kl3mwbaou/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import auth_vT9JWWT9pN from "/var/www/html/plugins/auth.ts";
import boot_OP3cd6SIk7 from "/var/www/html/plugins/boot.ts";
import window_mY4jZ4wBC5 from "/var/www/html/plugins/window.ts";
import payload_client_dZaQANHDdk from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._gldaeu2wafoqs5nf4t4kkwmlre/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_x70HTxgraJ from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._gldaeu2wafoqs5nf4t4kkwmlre/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_ysQmD54SLZ from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._gldaeu2wafoqs5nf4t4kkwmlre/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_qBegeeh1s6 from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._gldaeu2wafoqs5nf4t4kkwmlre/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_dAO6YaeMSf from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.8.1_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.9.0_sass@1.81.0_ters_6gedoixbxwzldasmjc74ht76uu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_osmjM5HyxA from "/var/www/html/node_modules/.pnpm/nuxt-auth-sanctum@0.4.18_magicast@0.3.5_rollup@4.25.0/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
export default [
  unhead_HfNdB4VzeL,
  router_W4flS4JDgo,
  _0_siteConfig_RILlUTRFhw,
  auth_vT9JWWT9pN,
  boot_OP3cd6SIk7,
  window_mY4jZ4wBC5,
  payload_client_dZaQANHDdk,
  navigation_repaint_client_x70HTxgraJ,
  chunk_reload_client_ysQmD54SLZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qBegeeh1s6,
  plugin_dAO6YaeMSf,
  plugin_osmjM5HyxA
]