export default defineNuxtPlugin({
  name: "window",
  dependsOn: [
    "nuxt:chunk-reload",
    "nuxt:global-components",
    "nuxt:prefetch",
    "nuxt:router",
    "nuxt-site-config:init",
  ],
  enforce: "pre", // or 'post'
  //parallel: true,
  async setup(nuxtApp: any) {
    const size = ref<{ width: Number | number; height: Number | number }>();
    // Window resize
    const handleResize = () => {
      let temp = {
        width: 0,
        height: 0,
      };
      temp.width = window.innerWidth;
      temp.height = window.innerHeight;
      size.value = temp;
    };
    const styleSize = computed<String | string>(() => {
      if (size?.value?.width >= 1536 && size?.value?.height >= 1536)
        return "2xl";
      else if (size?.value?.width >= 1280 && size?.value?.height >= 1280)
        return "xl";
      else if (size?.value?.width >= 1024 && size?.value?.height >= 1024)
        return "lg";
      else if (size?.value?.width >= 768 && size?.value?.height >= 768)
        return "md";
      else return "sm";
    });
    await nuxtApp.vueApp?.use({
      install(app: any, options: any) {
        app.mixin({
          mounted() {
            window.addEventListener("resize", handleResize);
            handleResize();
          },
          unmounted() {
            window.removeEventListener("resize", handleResize);
          },
        });
        app.provide("windowSize", size);
        app.provide("styleSize", styleSize);
      },
    });
    return {
      provide: {
        windowSize: size,
        styleSize: styleSize,
      },
    };
  },
});
