import './String';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Date {
    toString(format: String | string): string;
    toInputDateString(): String | string;
    toInputTimeString(): String | string;
    toInputDatetimeLocalString(): String | string;
  }

  interface DateConstructor {
    isDate(value: any): boolean;
    parseData(value: any): Date;
    toInputDateString(value: any): String | string;
    toInputTimeString(value: any): String | string;
    toInputDatetimeLocalString(value: any): String | string;
  }
}

// Formatting extension https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toString
Date.prototype.toString = function (format: String | string = 'YYYY-MM-DD HH:mm:ss'): string {
  const dayNames: String[] = ['Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota', 'Nedeľa'];
  let lastChar: String = '';
  let formatPart: String = '';
  let formatParts: String[] = [];
  let ret: String | string = '';
  for (const fc of format) {
    if ((lastChar != '') && (lastChar != fc)) {
      formatParts.push(formatPart);
      formatPart = '';
    }
    formatPart += fc;
    lastChar = fc;
  }
  if (formatPart.length != 0)
    formatParts.push(formatPart);
  for (formatPart of formatParts)
    switch (formatPart) {
      case 'YYYY':
        ret += this.getFullYear().toString();
        break;
      case 'YY':
        ret += (this.getFullYear() % 100).toString();
        break;
      case 'MM':
        ret += (this.getMonth() + 1).toString().padStart(2, '0');
        break;
      case 'M':
        ret += (this.getMonth() + 1).toString();
        break;
      case 'DD':
        ret += this.getDate().toString().padStart(2, '0');
        break;
      case 'D':
        ret += this.getDate().toString();
        break;
      case 'X': // day of week mon-sun
        ret += dayNames[this.getDay() - 1].toString();
        break;
      case 'x': // day of week 1-7
        let dow = this.getDay();
        if (dow == 0)
          dow = 7;
        ret += dow.toString();
        break;
      case 'HH':
        ret += this.getHours().toString().padStart(2, '0');
        break;
      case 'mm':
        ret += this.getMinutes().toString().padStart(2, '0');
        break;
      case 'ss':
        ret += this.getSeconds().toString().padStart(2, '0');
        break;
      case 'sss':
        ret += this.getMilliseconds().toString().padStart(3, '0');
        break;
      default:
        ret += formatPart.toString();
    }
  return (ret as string);
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
Date.prototype.toInputDateString = function (): String | string {
  return this.toString('YYYY-MM-DD');
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
Date.prototype.toInputTimeString = function (): String | string {
  return this.toString('HH:mm');
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
Date.prototype.toInputDatetimeLocalString = function (): String | string {
  return this.toString('YYYY-MM-DDTHH:mm');
};

Date.isDate = function (value: any): boolean {
  if (String.isString(value) || !isNaN(value))
    return !isNaN((new Date(value as string | number)).getTime());
  return (value instanceof Date);
}

// Simple funtion for parsing Date - value: null = now
Date.parseData = function (value: any): Date {
  if (String.isString(value) || !isNaN(value))
    return new Date(value as string | number);
  else if (value instanceof Date)
    return value as Date;
  return new Date();
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
Date.toInputDateString = function (value: any): String | string {
  return Date.parseData(value).toInputDateString();
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
Date.toInputTimeString = function (value: any): String | string {
  return Date.parseData(value).toInputTimeString();
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
Date.toInputDatetimeLocalString = function (value: any): String | string {
  return Date.parseData(value).toInputDatetimeLocalString();
};
