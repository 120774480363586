import './Object';

declare global {
  interface Array<T> {
    chunk(n: Number | number): Array<T>;
    intersect(value: Array<T>): Array<T>;
    diff(value: Array<T>): Array<T>;
    unique(): Array<T>;
    union(value: Array<T>): Array<T>;
    equal(value: Array<T>): boolean;
    includesAdv(searchElement: any, fromIndex?: number): boolean;
  }

  interface ArrayConstructor {
    diff(arrA: Array<any>, arrB: Array<any>): Array<any>; // Symetric diffrence !!!
    asArray<T>(arg: T | T[] | null): T[];
  }
}

Array.prototype.chunk = function (n: Number | number): Array<any> {
  let chunkLength = Math.max((this.length / (n as number)), 1);
  let chunks = [];
  for (let i = 0; i < (n as number); i++)
    if ((chunkLength * (i + 1)) <= this.length)
      chunks.push(this.slice((chunkLength * i), (chunkLength * (i + 1))));
  return chunks;
}

// Intersection
Array.prototype.intersect = function (value: Array<any>): Array<any> {
  if (Array.isArray(value) && Array.isArray(this) && (value.length > 0) && (this.length > 0))
    return value.filter(value => this.includesAdv(value));
  return [];
}

// Diffrence
Array.prototype.diff = function (value: Array<any>): Array<any> {
  if (Array.isArray(value) && Array.isArray(this) && (value.length > 0) && (this.length > 0))
    return this.filter(item => !value.includesAdv(item));
  if (Array.isArray(this) && (this.length > 0))
    return this;
  return [];
}

// Uniqe check
Array.prototype.unique = function (): Array<any> {
  let temp = [...new Set([...this])];
  if (temp.length > 0) {
    let result = [] as Array<any>;
    temp.forEach(function (elem) {
      if (Object.isObject(elem)) {
        if (result.filter(item => Object.equal(item, elem)).length < 1)
          result.push(elem);
      }
      else
        result.push(elem);
    });
    return result;
  }
  return [];
}

// Union/Uintersect
Array.prototype.union = function (value: Array<any>): Array<any> {
  if (Array.isArray(value) && Array.isArray(this) && (value.length > 0) && (this.length > 0))
    return [...this, ...value];
  if (Array.isArray(this) && (this.length > 0))
    return this;
  if (Array.isArray(value) && (value.length > 0))
    return value;
  return [];
}

// Equals
Array.prototype.equal = function (value: Array<any>): boolean {
  if (Array.isArray(value) && Array.isArray(this) && (value.length == this.length))
    return value.every(element => this.includes(element));
  return false;
}

// Includes with object comparsion
Array.prototype.includesAdv = function (searchElement: any, fromIndex?: number): boolean {
  let result = false;
  if (!Object.isObject(searchElement))
    return this.includes(searchElement, fromIndex);
  else
    for (let i = (fromIndex ?? 0); i < this.length; i++)
      if (Object.equal(this[i], searchElement)) {
        result = true;
        break;
      }
  return result;
}

// Symetric diffrence !!!
Array.diff = function (arrA: Array<any>, arrB: Array<any>): Array<any> {
  if (Array.isArray(arrA) && Array.isArray(arrB) && (arrA.length > 0) && (arrB.length > 0))
    return [...arrA.filter(item => !arrB.includesAdv(item)), ...arrB.filter(item => !arrA.includesAdv(item))];
  if (Array.isArray(arrA) && (arrA.length > 0))
    return arrA;
  if (Array.isArray(arrB) && (arrB.length > 0))
    return arrB;
  return [];
}

Array.asArray = function asArray<T>(arg: T | T[] | null): T[] {
  return (Array.isArray(arg) ? arg : ((arg !== null) ? [arg] : []));
}
